/**
 * 메뉴 목록을 관리하는 store
 */
export default defineStore('useUniverseStore', () => {
  const visible = ref(false);
  const usrGrpList = ref([]);
  const sysGrpList = ref([]);
  const details = ref([]);
  const orgCdList = ref([]);
  const popupProps = ref({});
  return { visible, usrGrpList, sysGrpList, details, orgCdList, popupProps };
});
